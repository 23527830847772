.register-interest {
    text-align: center;
    padding: 1rem;
    background-color: #e0e0e0;
    margin-top: 50px;
  }
  
  .register-interest h2 {
    font-size: 2rem;
    color: #ff6600; /* Adjust to match your color scheme */
  }
  
  .register-interest p {
    font-size: 1rem;
    color: #666;
  }
  
  .interest-form {
    margin-top: 1rem;
  }
  
  .interest-form input {
    padding: 0.5rem;
    font-size: 1rem;
    width: 60%;
    max-width: 300px;
    margin-right: 1rem;
  }
  
  .interest-form button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #ff6600; /* Adjust to match your brand */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .interest-form button:hover {
    background-color: #cc5200;
  }
  