.page-layout {
    padding: 0px;
    font-family: Arial, sans-serif;
    color: #333;
  }

  .page-header h1 {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    color: #ff6600;
    margin-bottom: 10px;
  }

  .page-description {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
    /* color: darkgray; */
    font-weight: bold;
  }
  
  .page-content {
    margin: 0 auto;
    /* max-width: 800px; */
  }
  