.hustle-list-container {

    a {
        display: inline-block;
        max-width: 90%;
        /* Adjust this width to fit your design */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li {
        margin-top: 10px;
        color: rgb(62, 62, 62);
        font-weight: bold;
        font-size: 1.3rem;
    }
}

.detailed-hustle-card {
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-top: 20px;
}